.root {
  margin-bottom: 8px;
  color: var(--mantine-color-darkPurple-9);
  width: 232px;
  padding-left: 4px;
  &:hover {
      border-radius: 4px;
      background-color: #FFFFFF80;
      color: var(--mantine-color-lightPurple-5);
  };

  &[data-active] {
      border-radius: 0px;
      background-color: var(--mantine-color-greyPurple-0);
      border-bottom: 2px solid var(--mantine-color-lightPurple-5);
      color: var(--mantine-color-lightPurple-5);
  };
}


.subsectionRoot {
  margin-bottom: 8px;
  color: var(--mantine-color-darkPurple-9);
  width: 232px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 100px; 
  &:hover {
      border-radius: 4px;
      background-color: #FFFFFF80;
      color: var(--mantine-color-lightPurple-5);
  };

  &[data-active] {
      border-radius: 0px;
      background-color: var(--mantine-color-greyPurple-0);
      border-bottom: 2px solid var(--mantine-color-lightPurple-5);
      color: var(--mantine-color-lightPurple-5);
  };
}

.label { 
  font-size: 14px;
  font-weight: 500;
}

.subsectionLabel { 
  font-size: 14px;
  font-weight: 400;
}

.chevron { 
  transform: rotate(90deg);
}

.problem {
  background: #fdd;
  border-bottom: 1px solid #f22;
  margin-bottom: -1px;
}
