.root {
    margin-left: 12px;
    height: 68px;
    margin-bottom: 16px;
    color: var(--mantine-color-darkPurple-9);
    font-weight: 600;
    &:hover {
        background-color: #FFFFFF80;
        color: var(--mantine-color-lightPurple-5);
    };
    &[data-active] {
        color: var(--mantine-color-lightPurple-5);
        border-bottom: 2px solid var(--mantine-color-lightPurple-5);
        background-color: var(--mantine-color-greyPurple-1);
    };
}

.label { 
  font-size: 16px;
}