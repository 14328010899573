.rpv-core__inner-page {
  background-color: var(--mantine-color-greyPurple-1) !important;
}

.rpv-core__page-layer {
  background-color: var(--mantine-color-greyPurple-1) !important;
}

.rpv-core__page-layer--single {
  background-color: var(--mantine-color-greyPurple-1) !important;
}

.rpv-core__canvas-layer {
  background-color: var(--mantine-color-greyPurple-1) !important;
}

.rpv-search__highlight {
  background-color: rgba(252, 221, 17, 0.4) !important;
  position: absolute;
  z-index: 1;
}

/*
There's no obvious way to prevent default highlighting behavior,
so instead we override the built-in "current highlight" style to
make it identical to the regular highlight style
and essentially make the default behavior a no-op
*/
.rpv-search__highlight--current{
  background-color: rgba(252, 221, 17, 0.4) !important;
  position: absolute;
  z-index: 1;
}

/*
Create a custom highlight style for when we actually want to highlight things
*/
.rpv-search__highlight--current_custom{
  background-color: rgba(114, 82, 254, 0.4);
  position: absolute;
  z-index: 1;
}

.rpv-default-layout__viewer{
  background-color: green !important;
}
.rpv-default-layout__container {
  border: 0 !important;
  background-color: green !important;
}

.rpv-default-layout__toolbar {
  background-color: var(--mantine-color-greyPurple-1) !important;
}