.paginationButton { 
  background-color: white;
  color: var(--mantine-color-lightPurple-5);
  font-size: 13px; 
  font-weight: 500; 
  &:disabled { 
    color: var(--mantine-color-greyPurple-3);
    &:hover {
      background-color: white; 
  }
  }
  &:hover {
    background-color: var(--mantine-color-lightPurple-1) 
}
}