.tr, .trClickable { 
  background-color: var(--mantine-color-red-0);
  height: 50px;
  padding-right: 20px;
}

.td { 
  text-align: left;
  border-bottom: 8px white solid;
  &:first-child { 
    border-radius: 0px 0px 0px 24px;
  }
  &:last-child { 
    border-radius: 0px 0px 24px 0px;
  }
}