.customControls{ 
  background-color: var(--mantine-color-lightPurple-5);
  color: white; 
  font-size: 14px;
  font-weight: 600;
  &:hover{
    background-color: var(--mantine-color-lightPurple-6);
  }
  &:disabled{ 
    background-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-5);
  }
}

.showSourcesButton{ 
  background-color: transparent;
  color: var(--mantine-color-lightPurple-5);
  border-color: var(--mantine-color-lightPurple-5);
  font-size: 14px;
  font-weight: 600;
  padding: 8 16 8 16;
  border-radius: 4px;
  width: 147px;
  &:disabled{ 
    background-color: var(--mantine-color-gray-1);
    border-color: var(--mantine-color-gray-1);
    color: var(--mantine-color-gray-5);
    cursor: not-allowed;
  }
}
