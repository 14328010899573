.controlsGroup { 
  border-radius: 0px;
  background-color: var(--mantine-color-lightPurple-0);
  margin-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.root { 
  border-radius: 4px;
  border-color: var(--mantine-color-lightPurple-0);
  background-color: var(--mantine-color-lightPurple-0);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content { 
  border-radius: 0px;
  background-color: var(--mantine-color-lightPurple-0);
  max-height: 250px;
}

.narrativeContent { 
  border-radius: 0px;
  background-color: var(--mantine-color-lightPurple-0);
  padding: 0px;
}

.control { 
  width: 28px;
  height: 28px;
}

.toolbar { 
  padding-top: 8px; 
  padding-left: 12px;
  border: 0px;
  background-color: var(--mantine-color-lightPurple-0);
  gap: 8px;
}
