.headerRoot {
    height: 68px; 
    border-bottom: solid 2px red;
    padding-left: 16px;
    padding-right: 20px;
}

.dropdown {
    border-radius: 8px;
    padding: 4px 0px;
    box-shadow: var(--mantine-shadow-md);
}

.item { 
    height: 56px;
    border-radius: 2px;
    justify-content: center;
    color: var(--mantine-color-darkPurple-9);
    font-size: var(--mantine-font-size-md);
    font-weight: 600;
    &[data-hovered] {
        border-radius: 2px;
        background-color: var(--mantine-color-gray-1);
      }
    }


