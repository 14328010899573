
.td { 
  text-align: left;
  padding-left: 24px;
}

.tr, .trClickable { 
  background-color: var(--mantine-color-white);
  height: 44px;
  padding-right: 20px;
  margin: 0px;
  border-bottom: solid 2px var(--mantine-color-lightPurple-1);
}

.trClickable {
  &:hover {
    cursor: pointer;
    background-color: var(--mantine-color-gray-1);
  }
}

.th { 
  text-align: left;
  padding-left: 24px;
}

.thead { 
  background-color: white;
}

.table {
  border-collapse: collapse;
  border-spacing: 0px 16px;
  width: 100%;
}
