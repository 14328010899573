.requirementRowGroup { 
  padding: 4px 0px 4px 40px;
  min-height: 32px;
  align-items: center;
}

.chevron {
  &[data-rotate] {
    transform: rotate(90deg);
  }
}
