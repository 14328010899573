.tr, .trClickable { 
  background-color: var(--mantine-color-red-0);
  height: 50px;
  border-radius: 24px; 
  padding-right: 20px;
  margin: 0px;
}
.trClickable {
  &:hover {
    box-shadow: var(--mantine-shadow-md);
    cursor: pointer;
  }
}

.td { 
  text-align: left;
  padding-left: 20px;
  &:first-child { 
    border-radius: 24px 0px 0px 24px;
    padding-left: 48px;
  }
  &:last-child { 
  border-radius: 0px 24px 24px 0px;
  padding-right: 20px
  }
}

.th { 
  padding-left: 20px;
  &:first-child { 
    padding-left: 48px;
  }
  text-align: left;
}

.thead { 
  background-color: white;
}
.table {
  border-collapse: separate;
  border-spacing: 0px 8px;
  width: 100%;
  overflow-wrap: anywhere;
}