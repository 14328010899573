.content { 
  border-radius: 0px;
  background-color: var(--mantine-color-lightPurple-0);
}

.card {
  border-radius: 8px;
  border: 1px solid var(--mantine-color-gray-1);
  display: flex;
  flex-direction: column;
}