.popoverMenuButtonItem {
  font-size: 16px;
  font-weight: 600;
  justify-content: start;
}

.popoverMenuButtonItemRoot, .popoverMenuButtonItemRootRed {
  margin: 0px;
  border-radius: 0px;
  height: 56px;
  background-color: white;
}

.popoverMenuButtonLeftSection {
  width: 26px; 
  height: 26px;
  justify-content: center;
}

.popoverMenuButtonItemRootRed {
  color: var(--mantine-color-red-5);
  &:disabled {
      color: var(--mantine-color-greyPurple-3);
      background-color: white;
      &:hover {
        background-color: white; 
        color: var(--mantine-color-greyPurple-3);
    }
  }
  &:hover {
    color: var(--mantine-color-red-5);
    background-color: var(--mantine-color-gray-1);
  }
}

.popoverDropdown {
  border-radius: 8px;
  padding: 4px 0px;
  box-shadow: var(--mantine-shadow-md);
}

.nestedMenuButtonRoot, .popoverMenuButtonItemRoot {
  color: var(--mantine-color-darkPurple-9);
  &:hover {
    color: var(--mantine-color-red-9);
    background-color: var(--mantine-color-gray-1);
  }
  &:disabled {
      color: var(--mantine-color-greyPurple-3);
      background-color: white;
      &:hover {
        background-color: white; 
    }
  }
}

.buttonTargetRoot, .buttonItemRoot {
  text-align: left;
  justify-content: start;
  group-align: start;
  &:hover { 
    background-color: var(--mantine-color-gray-1);
  }
}

.buttonTargetRootHovered {
  text-align: left;
  justify-content: start;
  group-align: start;
  background-color: var(--mantine-color-gray-1);
  &:hover { 
    background-color: var(--mantine-color-gray-1);
  }
}


.buttonItemRoot {
  border-radius: 0px;
}

.buttonTargetRoot, .buttonTargetRootHovered {
  border-radius: 8px;
  padding: 4px 8px;
}

.buttonItems { 
  justify-content: start;
}
