.root { 
  &:disabled {
    background-color: var(--mantine-color-greyPurple-1)
  }
}

.modalTextInput{
  &:disabled {
    color: black;
  }
}