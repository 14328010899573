.buttonRootVertical {
    &:hover {
        background-color: var(--mantine-color-red-1) 
    }
}

.buttonRootHorizontal {
  &:hover {
      background-color: var(--mantine-color-lightPurple-1) 
  }
}

.dropdown {
    padding: 12px;
}

.item {
    height: 35px;
    justify-content: center;
    color: var(--mantine-color-darkPurple-9);
    font-size: var(--mantine-font-size-md);
    font-weight: 700;
    &[data-hovered] {
        background-color: var(--mantine-color-lightPurple-1)
    }
}
