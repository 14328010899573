.root { 
  color: var(--mantine-color-lightPurple-9);
  radius: 4px;
  &:hover {
    radius: 4px;
    background-color: var(--mantine-color-lightPurple-1)
  }
  &[data-active]{
    color: var(--mantine-color-lightPurple-5);
  }
}

.boxRoot {
  &[data-active]{
    mb: 0px;
    h:41px;
    border-bottom: 2px solid var(--mantine-color-lightPurple-5);
  }

}