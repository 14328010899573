.primaryButton { 
  &:disabled { 
    background-color: var(--mantine-color-greyPurple-3);
    color: white
  }
  border-radius: 16px;
}

.closeButton { 
  float: right; 
  position: sticky;
  padding-bottom: 32px;
  padding-right: 0px;
  top: 12px; 
}